'use client'

import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useLocalStorage } from 'usehooks-ts'

import { syncAppConfig } from '@/configs/utils'
import { AppConfig } from '@/types/configs'
import { HUBS_STORE_LOCAL_KEY, HubsStoreState } from '@/types/store'

type ConfigsContextType = {
  store: HubsStoreState | null
  credentials: HubsStoreState['credentials'] | undefined
  profile: HubsStoreState['profile'] | undefined
  viveportAuth: HubsStoreState['viveportAuth'] | undefined
  communityToggle: HubsStoreState['communityToggle'] | undefined
  setStore: Dispatch<SetStateAction<HubsStoreState>>
  removeStore: () => void
  getHTCAccountId: () => string
  getAccountType: () => 'wallet_account' | 'htc_account' | 'guest'
}

const ConfigsContext = createContext<ConfigsContextType | null>(null)

type ConfigsProviderProps = PropsWithChildren<{
  appConfig: AppConfig
}>

export const ConfigsProvider = ({ appConfig, children }: ConfigsProviderProps) => {
  const [isLoadedAppConfig, setIsLoadedAppConfig] = useState(false)
  const [store, setStore, removeStore] = useLocalStorage<HubsStoreState>(HUBS_STORE_LOCAL_KEY, {})

  const credentials = store?.credentials
  const viveportAuth = store?.viveportAuth
  const communityToggle = store?.communityToggle
  const profile = store?.profile
  const email = credentials?.email

  const getAccountType = useCallback(() => {
    const walletAddress = viveportAuth?.walletAddress
    const accountEmail = viveportAuth?.accountEmail
    const accountPhoneNumber = viveportAuth?.accountPhoneNumber
    if (walletAddress) {
      return 'wallet_account'
    }
    if (accountEmail || accountPhoneNumber) {
      return 'htc_account'
    }
    return 'guest'
  }, [viveportAuth])

  const getHTCAccountId = useCallback(() => {
    if (email) {
      const atIndex = email.indexOf('@')
      return email.slice(0, atIndex)
    } else {
      return ''
    }
  }, [email])

  useEffect(() => {
    if (appConfig) {
      //* we use this way to make sure configs.appConfig in client side
      syncAppConfig(appConfig)
      setIsLoadedAppConfig(true)
    }
  }, [appConfig])

  return (
    //* we use this way to ensure that the children are rendered only after the appConfig is loaded
    <ConfigsContext.Provider
      value={{
        store,
        credentials,
        profile,
        viveportAuth,
        communityToggle,
        setStore,
        removeStore,
        getAccountType,
        getHTCAccountId,
      }}
    >
      {isLoadedAppConfig && children}
    </ConfigsContext.Provider>
  )
}

export const useConfigsContext = () => {
  const value = useContext(ConfigsContext)

  if (value == null) {
    throw new Error('useConfigsContext cannot be used outside of ConfigsProvider')
  }

  return value
}
