import { env } from 'next-runtime-env'
import { AppConfig } from '@/types/configs'
import configs from '@/configs'

const ensureEnvVariables = (value: unknown, fn: string) => {
  // use == to check if value is null or undefined
  if (value == null) {
    throw new Error(
      `Environment variable is not set, please check your ${fn} function or .env file`,
    )
  }

  return value
}

export const getHubsDomain = () => {
  const value = configs.appConfig?.features?.hubs_domain
  return ensureEnvVariables(value, 'getHubsDomain')
}

export const getCmsBaseURL = () => {
  const value = configs.appConfig?.features?.hubs_cms_base_url || env('NEXT_PUBLIC_CMS_SERVER')
  return ensureEnvVariables(value, 'getCmsBaseURL')
}

export const getCommunityServiceBaseURL = () => {
  const value =
    configs.appConfig?.features?.community_service_base_url ||
    env('NEXT_PUBLIC_COMMUNITY_SERVICE_BASE_URL')
  return ensureEnvVariables(value, 'getCommunityServiceBaseURL')
}

export const getViveAvatarDomain = () => {
  const value =
    configs.appConfig?.features?.vive_avatar_domain || env('NEXT_PUBLIC_VIVE_AVATAR_DOMAIN')
  return ensureEnvVariables(value, 'getViveAvatarDomain')
}

export const getEnableCommunity = () => {
  return configs.appConfig?.features?.enable_community === true
}

export const getEnableCommunityMobileApp = () => {
  return configs.appConfig?.features?.enable_community_mobileapp === true
}

export const getVodafoneURLPostfix = () => {
  return configs.appConfig?.features?.vodafone_url_postfix
}

export const getBISdkUri = () => {
  return configs.appConfig?.features?.bi_sdk_uri
}

export const getCommunityWebUrl = () => {
  return configs.appConfig?.features?.community_web_url
}

export const syncAppConfig = (appConfig: AppConfig) => {
  configs.appConfig = appConfig
}

export const enableExamplePage = () => {
  return process.env.NODE_ENV === 'development'
}

export const enableAuthCookieCheck = () => {
  return process.env.NODE_ENV !== 'development'
}

export const enableDevShareComponent = () => {
  return process.env.NODE_ENV === 'development'
}

export const enableCookieDomain = () => {
  return process.env.NODE_ENV !== 'development'
}
