const API_BASE_HUBS_CMS_V1 = '/api/hubs-cms/v1'
const API_BASE_HUBS_CMS_V2 = '/api/hubs-cms/v2'
const API_BASE_HUBS_CMS_V3 = '/api/hubs-cms/v3'
const API_BASE_HUBS_CMS_V4 = '/api/hubs-cms/v4'
const API_BASE_OAUTH_V3 = '/api/v3/oauth'
const API_BASE_COMMUNITY_SERVICE_V1 = '/api/community-service/v1'
const API_BASE_AVATAR = '/api/meetingareaselector/v1/newgenavatar'

export const API_ENDPOINT_AVATARS = `${API_BASE_HUBS_CMS_V1}/avatars`
export const API_ENDPOINT_MYAVATARS = `${API_BASE_HUBS_CMS_V1}/my-avatars`
export const API_GET_ROOMS_V1 = `${API_BASE_HUBS_CMS_V1}/rooms`
export const API_POST_ROOM_ACTION = `${API_BASE_HUBS_CMS_V1}/rooms`
export const API_GET_MY_ROOMS = `${API_BASE_HUBS_CMS_V1}/my-rooms`
export const API_DELETE_MY_ROOMS_V2 = `${API_BASE_HUBS_CMS_V1}/my-rooms`
export const API_GET_MY_PODS = `${API_BASE_HUBS_CMS_V1}/accounts`
export const API_GET_SCENES = `${API_BASE_HUBS_CMS_V1}/scenes`
export const API_ADD_ROOM_UPLOAD_ASSET = `${API_BASE_HUBS_CMS_V1}/upload-asset`
export const API_GET_USER_ROOMS = `${API_BASE_HUBS_CMS_V1}/rooms`
export const API_GET_THEMES = `${API_BASE_HUBS_CMS_V1}/themes`
export const API_GET_EDITOR_CHOICES = `${API_BASE_HUBS_CMS_V1}/editor-choices`
export const API_OOBE = `${API_BASE_HUBS_CMS_V1}/oobe`
export const API_RENEW_TOKEN = `${API_BASE_HUBS_CMS_V1}/auth/renew-token`
export const API_UPDATE_MY_ROOMS = `${API_BASE_HUBS_CMS_V1}/my-rooms`
export const API_THIRD_PARTY_OAUTH_LOGIN = `${API_BASE_HUBS_CMS_V1}/oauth/login`
export const API_GET_RANDOM_ROOMS = `${API_BASE_HUBS_CMS_V1}/random-rooms`
export const API_GET_REDIRECT_AVATAR = `${API_BASE_HUBS_CMS_V1}/redirect-avatar`
export const API_GET_MY_GIVEAWAY_LIST = `${API_BASE_HUBS_CMS_V1}/giveaways`
export const API_GET_SKETCHFAB_OBJECT_INFO = `${API_BASE_HUBS_CMS_V1}/sketchfab/models`
export const API_USER_VOUCHER_CHECK = `${API_BASE_HUBS_CMS_V1}/hubs/{hubSid}/voucher-check`
export const API_GET_VOUCHER_ROOMS = `${API_BASE_HUBS_CMS_V1}/vouchers`
export const API_REDEEM_MY_GIVEAWAY = `${API_BASE_HUBS_CMS_V1}/giveaways`
export const API_GET_EVENTS = `${API_BASE_HUBS_CMS_V1}/events`
export const API_GET_EVENT_DETAILS = `${API_BASE_HUBS_CMS_V1}/events/{eventId}`
export const API_GET_ACTIVITY_SHOWCASE = `${API_BASE_HUBS_CMS_V1}/activity-showcase`
export const API_GET_Recommend_CREATORS = `${API_BASE_HUBS_CMS_V1}/recommend-creators`
export const API_GET_ROOM_TAGS = `${API_BASE_HUBS_CMS_V1}/room-tags`

export const API_GET_ACCOUNTS_V2 = `${API_BASE_HUBS_CMS_V2}/accounts`
export const API_GET_LIKED_WORLDS = `${API_BASE_HUBS_CMS_V2}/me`
export const API_GET_ROOMS_V2 = `${API_BASE_HUBS_CMS_V2}/rooms`
export const API_GET_MY_ROOMS_V2 = `${API_BASE_HUBS_CMS_V2}/my-rooms`
export const API_ADD_MY_ROOMS_V2 = `${API_BASE_HUBS_CMS_V2}/my-rooms`
export const API_GET_USER_ROOMS_V2 = `${API_BASE_HUBS_CMS_V2}/rooms`
export const API_GET_RANDOM_ROOMS_V2 = `${API_BASE_HUBS_CMS_V2}/random-rooms`
export const API_GET_VOUCHER_ROOMS_V2 = `${API_BASE_HUBS_CMS_V2}/vouchers`

export const API_UPDATE_ACCOUNTS_V3 = `${API_BASE_HUBS_CMS_V3}/accounts`
export const API_GET_LIKED_WORLDS_V3 = `${API_BASE_HUBS_CMS_V3}/me`
export const API_GET_ME_V3 = `${API_BASE_HUBS_CMS_V3}/me`
export const API_GET_ROOMS_V3 = `${API_BASE_HUBS_CMS_V3}/rooms`
export const API_GET_ROOM_OBJECT = `${API_BASE_HUBS_CMS_V3}/room-objects`
export const API_POST_ROOM_OBJECT = `${API_BASE_HUBS_CMS_V3}/room-objects`
export const API_PATCH_ROOM_OBJECT = `${API_BASE_HUBS_CMS_V3}/room-objects`
export const API_DELETE_ROOM_OBJECT = `${API_BASE_HUBS_CMS_V3}/room-objects`
export const API_GET_NFT = `${API_BASE_HUBS_CMS_V3}/accounts`

export const API_UPDATE_ACCOUNTS_V4 = `${API_BASE_HUBS_CMS_V4}/accounts`
export const API_GET_ME_V4 = `${API_BASE_HUBS_CMS_V4}/me`
export const API_GET_GUEST_INFO = `${API_BASE_HUBS_CMS_V4}/accounts`

export const API_ENPOINT_LOGIN = `${API_BASE_OAUTH_V3}/login`
export const API_ENPOINT_LOGOUT = `${API_BASE_OAUTH_V3}/logout`
export const API_GET_LOGIN_STATUS = `${API_BASE_OAUTH_V3}/login-status`

export const API_GET_FREINDS = `${API_BASE_COMMUNITY_SERVICE_V1}/friends/status/get`
export const API_ADD_FREINDS = `${API_BASE_COMMUNITY_SERVICE_V1}/friend`
export const API_CANCEL_FRIEND_REQUEST = `${API_BASE_COMMUNITY_SERVICE_V1}/friend/send-request`
export const API_CHECK_USER_COMMUNITY_PERMISSION = `${API_BASE_COMMUNITY_SERVICE_V1}/isused`

export const API_GET_FOLLOWING_ROOMS = `${API_BASE_HUBS_CMS_V4}/me/following-rooms`
export const API_GET_CURRENT_AVATAR_LIST = `${API_BASE_AVATAR}/getcurrentavatarlist`

export const getApiPathAddWorldViewCount = (worldId: string) =>
  `${API_BASE_HUBS_CMS_V1}/hubs/${worldId}/viewed`
